import { useEffect, useRef, useState, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import {
  TableContainer,
  TableContent,
  TableHeader,
  TABLE_LAYOUT_OPTIONS,
  usePreventTableScrollingRef,
  Checkbox,
  withTooltip,
  TOOLTIP_HORIZONTAL_ALIGNMENTS,
  TOOLTIP_VERTICAL_ALIGNMENTS,
  THEMES,
  Button,
  BUTTON_VARIANTS,
  Loader,
} from "cdk-radial";
import classNames from "classnames";
import "./styles.css";
import ContractedBundlesDialog from "./ContractedBundlesDialog";
import { capitalizeVisibility } from "../Utils/constants";
const tooltipProps = {
  id: "info-icon-tooltip",
  styles: { width: "200px", maxWidth: "350px" },
  text: "This bundle is already contracted",
  delay: 0,
  theme: THEMES.LIGHT,
};

const EllipsisCellRenderer = ({ value }) => (
  <div className="ellipsis_cell_renderer">{value || ""}</div>
);

export function formatDate(dateString) {
  const date = new Date(dateString);
  const options = { month: "short", day: "numeric", year: "numeric" };
  let formattedDate = date.toLocaleDateString("en-US", options);

  // Add the ordinal suffix to the day
  const day = date.getDate();
  const suffixes = ["th", "st", "nd", "rd"];
  const suffix =
    suffixes[
      day % 10 < 4 && day % 10 > 0 && (day < 11 || day > 13) ? day % 10 : 0
    ];

  formattedDate = formattedDate.replace(/,/, "").replace(/(\d+)/, "$1" + suffix);
  return formattedDate;
}

const DateCellRenderer = ({ value }) => (
  <div className="ellipsis_cell_renderer">{formatDate(value)}</div>
);

const checkboxRenderer = ({ data, onCheckboxChange }) => {
  const CheckboxWithTooltip = withTooltip(Checkbox, tooltipProps);

  if (data.isAlreadyContracted) {
    return (
      <CheckboxWithTooltip
        dataTestId="select_bundle_for_contract_checkbox"
        type="checkbox"
        checked={data.isSelected || data.isAlreadyContracted}
        isDisabled={data.isAlreadyContracted}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => onCheckboxChange(e, data)}
        horizontalAlignment={TOOLTIP_HORIZONTAL_ALIGNMENTS.LEFT}
        verticalAlignment={TOOLTIP_VERTICAL_ALIGNMENTS.TOP}
      />
    );
  } else {
    return (
      <Checkbox
        dataTestId="select_bundle_for_contract_checkbox"
        type="checkbox"
        checked={data.isSelected || data.isAlreadyContracted}
        isDisabled={data.isAlreadyContracted}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => onCheckboxChange(e, data)}
      />
    );
  }
};

export default function ContractedBundlesTab({
  bundleList,
  contractedBundles,
  selectedBundles,
  setSelectedBundles,
}) {
  const [data, setData] = useState([]);
  const gridRef = useRef();
  const tableContentRef = usePreventTableScrollingRef();
  const [gridApi, setGridApi] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedApiInfo, setSelectedApiInfo] = useState(null);
  const [bundleData, setBundleData] = useState(null);

  const initializeData = useCallback(() => {
    const tableData =
      Array.isArray(bundleList.items) &&
      bundleList.items?.map((bundle) => ({
        bundle_name: bundle?.name,
        created_by: bundle?.createdBy,
        created_on: bundle?.created,
        publisher: bundle?.publisherName,
        visibility: capitalizeVisibility(bundle?.visibility),
        num_of_apis: bundle?.apis.length,
        all_details: bundle,
        isAlreadyContracted:
          Array.isArray(contractedBundles) &&
          contractedBundles.some((a) => a.contractId === bundle?.id),
        isSelected:
          Array.isArray(selectedBundles) &&
          selectedBundles.some((a) => a?.id === bundle?.id),
      }));
    setData(tableData);
  }, [bundleList, contractedBundles, selectedBundles]);

  useEffect(() => {
    initializeData();
  }, [initializeData]);

  const buttonWithToolTip = field =>params => {
    
    return (
      <div className="certification_view_tooltip">
        <Button
          onClick={() => {
            onCellClicked;
          }}
          text={params.value}
          variant={BUTTON_VARIANTS.TEXT}
        />
      </div>
    );
    /* eslint-disable react/prop-types */
  };

  const CustomNoRowsOverlay = ({}) => (
    <div className="empty_no_rows_overlay">
      <Loader
        className="body-loader"
        data-testid="manage_contract_apis_loader"
        label="Loading..."
        variant="inline"
      />
    </div>
  );

  const columns = (onCheckboxChange) => [
    {
      field: "checkbox",
      headerName: "",
      maxWidth: 55,
      minWidth: 55,
      resizable: false,
      cellRendererFramework: (params) =>
        checkboxRenderer({ ...params, onCheckboxChange }),
    },
    {
      cellRendererFramework: EllipsisCellRenderer,
      field: "bundle_name",
      headerName: "Bundle Name",
      minWidth: 300,
      sortable: true,
    },
    {
      cellRendererFramework: buttonWithToolTip('num_of_apis'),
      field: "num_of_apis",
      headerName: "No of APIs",
      sortable: true,
      minWidth: 150,
      maxWidth: 160,
      onCellClicked,
    },
    {
      cellRendererFramework: EllipsisCellRenderer,
      field: "created_by",
      headerName: "Created By",
      minWidth: 300,
    },
    {
      cellRendererFramework: DateCellRenderer,
      field: "created_on",
      headerName: "Created on",
      minWidth: 300,
    },
    {
      cellRendererFramework: EllipsisCellRenderer,
      field: "visibility",
      headerName: "Visibility",
      minWidth: 300,
    },
  ];
  const onCellClicked = (event) => {
    setDialogOpen(true);
    setSelectedApiInfo(event.data.all_details.apis);
    setBundleData(event.data.bundle_name);
  };

  const onCheckboxChange = useCallback(
    (e, row) => {
      const isChecked = e.target.checked;
      setSelectedBundles((prevSelected) => {
        const updatedSelected = isChecked
          ? [...prevSelected, row.all_details]
          : prevSelected.filter((bundle) => bundle.id !== row.all_details.id);

        setData((prevData) =>
          prevData.map((bundle) =>
            bundle.id === row.id ? { ...bundle, isSelected: isChecked } : bundle
          )
        );
        return updatedSelected;
      });
    },
    [setSelectedBundles, setData]
  );

  const handleGridReady = useCallback((gridParams) => {
    setGridApi(gridParams.api);
    gridParams.api.sizeColumnsToFit();
    gridParams.api.refreshCells({ force: true });
  }, []);

  const headerProps = {
    dataTestId: `contracted-bundles-table-header`,
    hideBorder: false,
    hideTableHeader: true,
    hideTableTitle: true,
    id: `contracted-bundles-table-header`,
    isAlwaysExpanded: false,
    isSearchable: false,
    isPrintable: false,
    isDownloadable: false,
    overflowButtonProps: undefined,
  };

  return (
    <>
      <TableContainer
        id="ag-grid-container"
        className={classNames("contracted-bundles-tab_grid", "ag-theme-balham")}
      >
        <TableHeader {...headerProps} />

        <TableContent
          data-testid="table-content"
          className="contracted-bundles_tab_grid-content"
          ref={tableContentRef}
        >
          <AgGridReact
            columnDefs={columns(onCheckboxChange)}
            domLayout={TABLE_LAYOUT_OPTIONS.AUTO_HEIGHT}
            rowData={data}
            rowHeight={50}
            style={{ width: "100%" }}
            ref={gridRef}
            onGridReady={handleGridReady}
            rowSelection="multiple"
            suppressRowClickSelection={true}
            suppressScrollOnNewData={true}
            frameworkComponents={{
              customNoRowsOverlay: CustomNoRowsOverlay,
            }}
            noRowsOverlayComponent="customNoRowsOverlay"
          />
        </TableContent>
      </TableContainer>
      {dialogOpen && (
        <ContractedBundlesDialog
          selectedApiInfo={selectedApiInfo}
          bundleData={bundleData}
          dialogOpen={dialogOpen}
          setDialogOpen={setDialogOpen}
        />
      )}
    </>
  );
}

import React, { useState } from "react";
import { fortellisTheme, Dialog, BUTTON_VARIANTS, RadioGroup } from "cdk-radial";
import { ThemeProvider } from "styled-components";

const DEFINED_ORG_TYPES = [
  { label: "Dealer", value: "Dealer" },
  { label: "ISV", value: "ISV" },
  { label: "OEM", value: "OEM" },
  { label: "Internal", value: "Internal" },
  { label: "Test", value: "Test" }
];

const FortellisDefinedTypeDialog = ({ open, entity, saveChanges, onClose }) => {

  const [selectedType, setSelectedType] = useState(entity?.fortellisDefinedType);

  return (
    <div>
      <ThemeProvider theme={fortellisTheme}>
        <Dialog
          buttonsProps={[
            {
              id: "defined-type-dialog-action-cancel",
              onClick: () => onClose(),
              text: "Cancel",
              variant: BUTTON_VARIANTS.TEXT,
            },
            {
              id: "defined-type-dialog-action-ok",
              onClick: () => saveChanges(entity, selectedType),
              text: "Save",
              variant: BUTTON_VARIANTS.PRIMARY,
            }
          ]}
          id="defined-type-dialog"
          dataTestId="defined-type-dialog"
          isOpen={open}
          onClose={() => onClose()}
          title="Fortellis-Defined Org Type"
        >
          <RadioGroup
            label="Fortellis-defined type"
            dataTestId="defined-type-selector"
            hideLabel
            options={DEFINED_ORG_TYPES}
            value={selectedType}
            onChange={e => {
              setSelectedType(e.target.value);
            }}
          />
        </Dialog>
      </ThemeProvider>
    </div>
  )
}

export default FortellisDefinedTypeDialog;

import React, { Component } from "react";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  DialogFooterButton
} from "@cdk-uip/react-dialog";
import { TextArea } from "@cdk-uip/react-text-area";
import { Typography } from "@cdk-uip/react-typography";

export default class FeatureGatesDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      featureGates: "",
      errorMessage: ""
    };
  }

  componentDidMount() {
    const { entity } = this.props;
    this.setState({
      featureGates: (entity ? entity.featureGates : "") || "",
      errorMessage: ""
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { entity } = this.props;
    const prevId = prevProps.entity ? prevProps.entity.id : undefined;
    const curId = entity ? entity.id : undefined;

    if (prevId !== curId) {
      // editing a different org
      this.setState({
        featureGates: (entity ? entity.featureGates : "") || "",
        errorMessage: ""
      });
    }
  }

  handleChange = e => {
    this.setState({
      featureGates: e.target.value
    });
  };

  saveChanges = () => {
    this.setState({
      errorMessage: ""
    });
    this.props
      .saveChanges(this.props.entity, this.state.featureGates)
      .then(() => {
        this.props.onClose();
      })
      .catch(err => {
        console.error(err);
        this.setState({
          errorMessage: err.message || "An error occurred."
        });
      });
  };

  render() {
    const { open, onClose } = this.props;
    return (
      <Dialog open={open} onCancel={onClose}>
        <DialogHeader>Manage Feature Gates</DialogHeader>
        <DialogBody>
          <TextArea
            fullWidth
            rows={4}
            className="manage-feature-gates-text-area"
            label="Feature Gates"
            value={this.state.featureGates}
            onChange={this.handleChange}
          ></TextArea>
        </DialogBody>
        <DialogFooter>
          <Typography className="manage-feature-gates-error">
            {this.state.errorMessage}
          </Typography>
          <DialogFooterButton
            data-testid="manage-feature-gates-button-save"
            onClick={this.saveChanges}
          >
            Save
          </DialogFooterButton>
          <DialogFooterButton
            data-testid="manage-feature-gates-button-cancel"
            onClick={onClose}>
            Cancel
          </DialogFooterButton>
        </DialogFooter>
      </Dialog>
    );
  }
}

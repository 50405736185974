import { useState, useEffect } from "react";
import {
  Input,
  INPUT_SIZES,
  BUTTON_VARIANTS,
  Dialog,
  Checkbox,
  CHECKBOX_SIZES,
  TextArea,
  TOAST_VARIANTS,
  Button,
  IconCheck
} from "cdk-radial";
import "./style.css";
import styled from "styled-components";
const regEx = /^[0-9]*$/;
const INPUTS = {
  CATALOG: {
    TRANSACTIONAL: {
      name: "tiered_plan",
      label: "Transactional",
      id: "api-edit-transactional-catalog"
    },
    SUB_BASE: {
      name: "advanced_plan_base_subscription",
      label: "Subscription w/ Overage (Base)",
      id: "api-edit-sub-base-catalog"
    },
    SUB_OVERAGE: {
      name: "advanced_plan_overage",
      label: "Subscription w/ Overage (Overage)",
      id: "api-edit-sub-overage-catalog"
    }
  },
  WEIGHT: {
    helperText: "Weight must be between 0 and 1",
    id: "api-edit-api-weight",
    label: "API weight",
    name: "api_weight"
  },
  SYSTEM_SETTINGS: {
    helperText: "Enter valid JSON",
    id: "system-settings-text-area",
    label: "System Settings",
    name: "system_settings"
  }
};

const WEIGHT_ERROR_MSG = "Weight must be between 0 and 1.";
const SYS_SETTINGS_ERROR_MSG = "Invalid JSON syntax";
const SAVE_SUCCESS_MSG = "API changes updated successfully!";
const API_APPROVE_MSG = "Are you sure you want to approve this API?";
const StyledDialog = styled(Dialog)`
  max-width: 450px;
`;
export default function ApiEditDialog(props) {
  const {
    editedApi,
    updateApiMetadata,
    closeAfterSave,
    apiEditResponse,
    awaitingResponse,
    showToastNotif,
    errorMessage,
    apiEditError,
    clearApiUpdateData,
  } = props;
  const [isFeatured, setIsFeatured] = useState(false);
  const [apiWeight, setApiWeight] = useState("");
  const [catalogNumbers, setCatalogNumbers] = useState({});
  const [systemSettings, setSystemSettings] = useState("");
  const [weightError, setWeightError] = useState(false);
  const [settingError, setSettingError] = useState(false);
  const [open, setOpen] = useState(false);
  const [approveButtonVisibility, setApproveButtonVisibility] = useState(false);

  const getCatalogFormated = () => {
    const catalogsFormatted = [];
    Object.keys(INPUTS.CATALOG).forEach(k => {
      if (
        !editedApi?.isAsyncApi ||
        INPUTS.CATALOG[k].name !== INPUTS.CATALOG.TRANSACTIONAL.name
      ) {
        catalogsFormatted.push({
          type: INPUTS.CATALOG[k].name,
          value: catalogNumbers[INPUTS.CATALOG[k].name] || ""
        });
      }
    });
    return catalogsFormatted;
  }
  const handleOpen = () => {
    setOpen(true);
  }
  const handleCancel = () => {
    setOpen(false);
  }
  const openApproveConfirmation = () => {
    handleOpen();
  };
  const handleApprove = async () => {
    const catalogsFormatted = getCatalogFormated();
    setOpen(false);
    setApproveButtonVisibility(false);
    await updateApiMetadata(
      editedApi.id,
      isFeatured,
      apiWeight,
      catalogsFormatted,
      systemSettings,
      editedApi.isAsyncApi,
      true
    );
  };

  useEffect(() => {
    if (editedApi) {
      const catalogs = {};
      editedApi.catalogs?.forEach(i => {
        catalogs[i.type] = i.value;
      });
      setCatalogNumbers(catalogs);
      setSystemSettings(JSON.stringify(editedApi.systemSettings));
      setApproveButtonVisibility(editedApi.status ==='in_review' && editedApi.isAsyncApi)
      if (!editedApi.isAsyncApi) {
        const weight =
          editedApi.search?.weight === 0 ? "0" : editedApi.search?.weight || "";
        setApiWeight(weight);
        setIsFeatured(editedApi.search?.featured || false);
        setWeightError(!weight);
      }
    }
  }, [editedApi]);

  useEffect(() => {
    if (!awaitingResponse && !apiEditError && apiEditResponse) {
      showToastNotif(SAVE_SUCCESS_MSG, TOAST_VARIANTS.POSITIVE);
      closeAfterSave();
    } else if (apiEditError) {
      const error = errorMessage.generateMessage;
      showToastNotif(`Error: ${error}`);
      clearApiUpdateData();
    }
  }, [awaitingResponse, apiEditResponse, apiEditError]);

  const handleInputChange = event => {
    if (event.target.name === INPUTS.SYSTEM_SETTINGS.name)
      setSystemSettings(event.target.value);
    else if (event.target.name === INPUTS.WEIGHT.name) {
      setApiWeight(event.target.value);
    } else {
      if (regEx.test(event.target.value)) {
        setCatalogNumbers({
          ...catalogNumbers,
          [event.target.name]: event.target.value
        });
      }
    }
  };

  const handleCheckboxChange = event => {
    setIsFeatured(event.target.checked);
  };

  const handleInputBlur = () => {
    setSettingError(!validateSystemSettings(systemSettings));
    if (!editedApi?.isAsyncApi) {
      setWeightError(!validateWeight(apiWeight));
    }
  };

  const validateWeight = value => {
    if (value === "") return false;
    const nWeight = Number(value);
    if (!nWeight && nWeight !== 0) return false;
    else if (nWeight < 0 || nWeight > 1) return false;
    return true;
  };

  const validateSystemSettings = value => {
    try {
      JSON.parse(value);
      return true;
    } catch (e) {
      return false;
    }
  };

  const handleClose = () => {
    setWeightError(false);
    props.close();
  };

  const handleSave = async () => {
    const catalogsFormatted = getCatalogFormated();
    await updateApiMetadata(
      editedApi.id,
      isFeatured,
      apiWeight,
      catalogsFormatted,
      systemSettings,
      editedApi.isAsyncApi
    );
  };

  return (
    <>
      <Dialog
        id="api-edit"
        buttonsProps={[
          {
            id: "api-edit-dialog-cancel",
            onClick: handleClose,
            text: "Cancel",
            variant: BUTTON_VARIANTS.TEXT,
            isDisabled: awaitingResponse,
          },
          {
            id: "api-edit-dialog-save",
            onClick: handleSave,
            text: "Save",
            variant: BUTTON_VARIANTS.PRIMARY,
            style: { marginRight: "16px" },
            isDisabled: weightError || settingError,
            // isLoading: awaitingResponse,
          },
        ]}
        isOpen={props.open}
        title={`Edit API : ${editedApi?.name}`}
        hideCloseButton={true}
        disableOverlayClicked={true}
        style={{ maxWidth: "800px" }}
      >
        <div
          className="api-admin-api-edit-dialog-body"
          onBlur={handleInputBlur}
        >
          <div
            className={!editedApi?.isAsyncApi ? "api-edit-sub-container" : ""}
          >
            {!editedApi?.isAsyncApi && (
              <div>
                <label>
                  <span>API Weight</span>
                </label>
                <Input
                  helperText={INPUTS.WEIGHT.helperText}
                  id={INPUTS.WEIGHT.id}
                  label={INPUTS.WEIGHT.label}
                  name={INPUTS.WEIGHT.name}
                  onChange={handleInputChange}
                  size={INPUT_SIZES.LARGE}
                  value={apiWeight || ""}
                  className={"api-edit-input"}
                  isRequired={true}
                  hasError={weightError}
                  errorMessage={WEIGHT_ERROR_MSG}
                  enableCustomValidation
                  onBlur={handleInputBlur}
                  dataTestId={`input-${INPUTS.WEIGHT.name}`}
                />
              </div>
            )}

            <label>
              <span>Catalog Numbers</span>
            </label>
            {Object.keys(INPUTS.CATALOG).map((key) => {
              if (
                editedApi?.isAsyncApi &&
                INPUTS.CATALOG[key].name === INPUTS.CATALOG.TRANSACTIONAL.name
              )
                return false;
              return (
                <Input
                  id={INPUTS.CATALOG[key].id}
                  label={INPUTS.CATALOG[key].label}
                  name={INPUTS.CATALOG[key].name}
                  onChange={handleInputChange}
                  size={INPUT_SIZES.LARGE}
                  value={catalogNumbers[INPUTS.CATALOG[key].name] || ""}
                  className={"api-edit-input"}
                  maxLength={8}
                  hasCharacterCount
                  dataTestId={`input-${INPUTS.CATALOG[key].name}`}
                />
              );
            })}
            {approveButtonVisibility && (
              <Button
                text="Approve"
                variant="secondary"
                icon={<IconCheck />}
                className="margin-sides-5"
                onClick={openApproveConfirmation}
              />
            )}
          </div>
          <div className="api-edit-sub-container">
            {!editedApi?.isAsyncApi && (
              <div>
                <div>
                  <label>
                    <span>Featured API</span>
                  </label>
                </div>
                <Checkbox
                  checked={isFeatured}
                  label="Featured"
                  name="api-edit-featured"
                  onChange={handleCheckboxChange}
                  size={CHECKBOX_SIZES.STANDARD}
                  style={{ marginTop: "2px" }}
                />
              </div>
            )}
            <div>
              <div>
                <label>
                  <span>Privacy Protection</span>
                </label>
              </div>
              <Checkbox
                name="api-edit-privacy-protection"
                label="Consumer Privacy Protection"
                checked={editedApi?.privacyProtection}
                isDisabled={true}
                onChange={() => {}}
                style={{ marginTop: "2px" }}
              />
            </div>
            <div>
              <div>
                <label>
                  <span>API Field Management</span>
                </label>
              </div>
              <Checkbox
                name="api-edit-field-management"
                label="API Field Management"
                checked={editedApi?.verticalScoping}
                isDisabled={true}
                onChange={() => {}}
                style={{ marginTop: "2px" }}
              />
            </div>
            <div className="text-area-wrapper">
              <label>
                <span>System Settings</span>
              </label>
              <TextArea
                helperText={INPUTS.SYSTEM_SETTINGS.helperText}
                dataTestId={`input-${INPUTS.SYSTEM_SETTINGS.name}`}
                id={INPUTS.SYSTEM_SETTINGS.id}
                label={INPUTS.SYSTEM_SETTINGS.label}
                name={INPUTS.SYSTEM_SETTINGS.name}
                onChange={handleInputChange}
                value={systemSettings || ""}
                size={INPUT_SIZES.LARGE}
                rows={9}
                onBlur={handleInputBlur}
                hasError={settingError}
                enableCustomValidation
                errorMessage={SYS_SETTINGS_ERROR_MSG}
              />
            </div>
          </div>
        </div>
      </Dialog>
      <StyledDialog
        buttonsProps={[
          {
            id: "dialog-3-action-1",
            onClick: handleCancel,
            text: "Cancel",
            variant: BUTTON_VARIANTS.TEXT,
          },
          {
            id: "dialog-3-action-2",
            onClick: handleApprove,
            text: "Proceed",
            variant: BUTTON_VARIANTS.PRIMARY,
          },
        ]}
        id="dialog-3"
        isOpen={open}
        onClose={handleCancel}
        title="Approve Async API"
      >
        {API_APPROVE_MSG}
      </StyledDialog>
    </>
  );
};

import types from "./types";
import { CALL_API } from "@cdkglobal/react-core-reduxapi";
import config from "../config/app.conf.json";

const solutionsReqObj = {
  url: config.api.subscriptions_solutions,
  method: "GET"
};

const SUBSRIPTION_SERVICE_BASE_URI = config.api.subscriptions_base_uri;
const CONNECTION_SERVICE_BASE_URI = config.api.connections_base_uri;
const PROVIDER_SERVICE_BASE_URI = `${config.api.provider_api_url}/providers`;
const ORG_SERVICE_BASE_URI = config.api.organizations_uri;
const ASYNC_APIS_CONTROL_URL = config.api.async_apis_control_url;
const SUBSRIPTION_SERVICE_V1_BASE_URI = config.api.subscriptions_v1_base_uri;

export const actions = {
  resetSubscriptions: () => {
    return { type: types.RESET_SUBSCRIPTIONS };
  },
  getSolutions: accessToken => ({
    [CALL_API]: {
      ...solutionsReqObj,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json"
      },
      types: [
        types.SOLUTIONS_GET_REQUEST,
        types.SOLUTIONS_GET_RECEIVE,
        types.SOLUTIONS_GET_ERROR
      ]
    }
  }),
  createSubscription: ({
    accessToken,
    entityId,
    solutionId,
    displayName,
    environment
  }) => ({
    [CALL_API]: {
      url: SUBSRIPTION_SERVICE_BASE_URI,
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json"
      },
      data: { entityId, solutionId, environment },
      types: [
        types.CREATE_SUB_POST_REQUEST,
        { type: types.CREATE_SUB_POST_RECEIVE, displayName },
        { type: types.CREATE_SUB_POST_ERROR, displayName }
      ]
    }
  }),
  getEntitySubscriptions: ({
    accessToken,
    entityId,
    entityName = "EntityNameGoesHere"
  }) => ({
    [CALL_API]: {
      url: `${SUBSRIPTION_SERVICE_V1_BASE_URI}?entityId=${entityId}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json"
      },
      types: [
        {
          type: types.GET_ENTITY_SUB_REQUEST,
          entityId,
          entityName
        },
        {
          type: types.GET_ENTITY_SUB_RECEIVE,
          entityId,
          entityName
        },
        {
          type: types.GET_ENTITY_SUB_ERROR,
          entityId,
          entityName
        }
      ]
    }
  }),
  getSubscriptionInfo: ({ accessToken, subscriptionId }) => ({
    [CALL_API]: {
      url: `${SUBSRIPTION_SERVICE_BASE_URI}/${subscriptionId}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json"
      },
      types: [
        { type: types.GET_SUB_BY_ID_REQUEST },
        {
          type: types.GET_SUB_BY_ID_RECEIVE,
          subscriptionId
        },
        {
          type: types.GET_SUB_BY_ID_ERROR,
          subscriptionId
        }
      ]
    }
  }),
  getSubSolutionInfo: ({ accessToken, solutionId }) => ({
    [CALL_API]: {
      url: `${config.api.solutions_url}/${solutionId}?expand=true`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json"
      },
      types: [
        { type: types.GET_SUB_SOLUTION_REQUEST },
        {
          type: types.GET_SUB_SOLUTION_RECEIVE,
          solutionId
        },
        {
          type: types.GET_SUB_SOLUTION_ERROR,
          solutionId
        }
      ]
    }
  }),
  getSubConnectionInfo: ({ accessToken, subscriptionId }) => ({
    [CALL_API]: {
      url: `${CONNECTION_SERVICE_BASE_URI}?subscriptionId=${subscriptionId}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json"
      },
      types: [
        { type: types.GET_SUB_CONNECTIONS_REQUEST },
        {
          type: types.GET_SUB_CONNECTIONS_RECEIVE,
          subscriptionId
        },
        {
          type: types.GET_SUB_CONNECTIONS_ERROR,
          subscriptionId
        }
      ]
    }
  }),
  getOrg: ({ accessToken, orgId }) => ({
    [CALL_API]: {
      url: `${ORG_SERVICE_BASE_URI}/${orgId}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json"
      },
      types: [
        { type: types.GET_ORG_REQUEST },
        {
          type: types.GET_ORG_RECEIVE,
          orgId
        },
        {
          type: types.GET_ORG_ERROR,
          orgId
        }
      ]
    }
  }),
  getprovidersForAPIProduct: ({ accessToken, apis = [] }) => ({
    [CALL_API]: {
      url: `${PROVIDER_SERVICE_BASE_URI}/list`,
      method: "POST",
      data: { apis, statuses: ["registered"] },
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json"
      },
      types: [
        { type: types.GET_PROVIDERS_FOR_API_REQUEST },
        {
          type: types.GET_PROVIDERS_FOR_API_RECEIVE,
          api: apis.toString()
        },
        {
          type: types.GET_PROVIDERS_FOR_API_ERROR,
          api: apis.toString()
        }
      ]
    }
  }),
  getProviderById: ({ accessToken, providerId }) => ({
    [CALL_API]: {
      url: `${PROVIDER_SERVICE_BASE_URI}/${providerId}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json"
      },
      types: [
        { type: types.GET_PROVIDER_BY_ID_REQUEST, providerId },
        { type: types.GET_PROVIDER_BY_ID_RECEIVE, providerId },
        { type: types.GET_PROVIDER_BY_ID_ERROR, providerId }
      ]
    }
  }),
  activateConnectionWithProvider: ({
    accessToken,
    connectionId,
    providerId,
    api
  }) => ({
    [CALL_API]: {
      url: `${CONNECTION_SERVICE_BASE_URI}/${connectionId}/enable`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json"
      },
      data: { providerId },
      types: [
        types.ACTIVATE_CONNECTION_REQUEST,
        { type: types.ACTIVATE_CONNECTION_RECEIVE, api },
        { type: types.ACTIVATE_CONNECTION_ERROR, api }
      ]
    }
  }),
  disableConnection: ({ accessToken, connectionId, api }) => ({
    [CALL_API]: {
      url: `${CONNECTION_SERVICE_BASE_URI}/${connectionId}/disable`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json"
      },
      types: [
        types.DISABLE_CONNECTION_REQUEST,
        { type: types.DISABLE_CONNECTION_RECEIVE, api },
        { type: types.DISABLE_CONNECTION_ERROR, api }
      ]
    }
  }),
  enableSubscription: ({ accessToken, subscriptionId, displayName }) => ({
    [CALL_API]: {
      url: `${SUBSRIPTION_SERVICE_BASE_URI}/${subscriptionId}/enable`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json"
      },
      types: [
        types.POST_ENABLE_SUB_REQUEST,
        { type: types.POST_ENABLE_SUB_RECEIVE, displayName },
        { type: types.POST_ENABLE_SUB_ERROR, displayName }
      ]
    }
  }),
  disableSubscription: ({ accessToken, subscriptionId, displayName }) => ({
    [CALL_API]: {
      url: `${SUBSRIPTION_SERVICE_BASE_URI}/${subscriptionId}/disable`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json"
      },
      types: [
        types.POST_DISABLE_SUB_REQUEST,
        { type: types.POST_DISABLE_SUB_RECEIVE, displayName },
        { type: types.POST_DISABLE_SUB_ERROR, displayName }
      ]
    }
  }),
  getEntityAsyncApiAccessGrants: ({ accessToken, dataOwnerOrgId }) => ({
    [CALL_API]: {
      url: `${ASYNC_APIS_CONTROL_URL}/v1/admin/access-grants?dataOwnerOrgId=${dataOwnerOrgId}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json"
      },
      types: [
        {
          type: types.GET_ENTITY_ASYNC_ACCESS_GRANTS_REQUEST,
          dataOwnerOrgId
        },
        {
          type: types.GET_ENTITY_ASYNC_ACCESS_GRANTS_RECEIVE,
          dataOwnerOrgId
        },
        {
          type: types.GET_ENTITY_ASYNC_ACCESS_GRANTS_ERROR,
          dataOwnerOrgId
        }
      ]
    }
  }),
  upsertAsyncAccessGrants: ({
    accessToken,
    dataOwnerOrgId,
    integrationId,
    api,
    subscriptionId
  }) => ({
    [CALL_API]: {
      url: `${ASYNC_APIS_CONTROL_URL}/v1/admin/access-grants`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json"
      },
      data: { dataOwnerOrgId, integrationId, subscriptionId },
      types: [
        {
          type: types.UPSERT_ASYNC_ACCESS_GRANTS_REQUEST,
          api
        },
        {
          type: types.UPSERT_ASYNC_ACCESS_GRANTS_RECEIVE,
          api
        },
        {
          type: types.UPSERT_ASYNC_ACCESS_GRANTS_ERROR,
          api
        }
      ]
    }
  }),
  disableAsyncAccessGrants: ({ accessToken, id, integrationId, api }) => ({
    [CALL_API]: {
      url: `${ASYNC_APIS_CONTROL_URL}/v1/admin/access-grant/${id}`,
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json"
      },
      types: [
        {
          type: types.DISABLE_ASYNC_ACCESS_GRANTS_REQUEST,
          integrationId,
          api
        },
        {
          type: types.DISABLE_ASYNC_ACCESS_GRANTS_RECEIVE,
          integrationId,
          api
        },
        {
          type: types.DISABLE_ASYNC_ACCESS_GRANTS_ERROR,
          integrationId,
          api
        }
      ]
    }
  })
};

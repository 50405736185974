import React from "react";
import { AgGridReact } from "ag-grid-react";
import {useRef, useState, useCallback } from "react";
import {
  TableContainer,
  TableContent,
  TABLE_LAYOUT_OPTIONS,
  usePreventTableScrollingRef,
  Dialog, DialogHeader
} from "cdk-radial";
import classNames from "classnames";
import "./styles.css";
import { capitalizeVisibility, getTypeOfApis } from "../Utils/constants";



export default function ContractedBundlesDialog({
  dialogOpen, selectedApiInfo,bundleData, setDialogOpen }) {


  const EllipsisCellRenderer = ({ value }) => (
    <div className="ellipsis_cell_renderer">{value || ""}</div>
  );
const [gridApi, setGridApi] = useState(null);
const gridRef = useRef();
const tableContentRef = usePreventTableScrollingRef();

const columns  = [
  {
    cellRendererFramework: EllipsisCellRenderer,
    field: "api_name",
    headerName: "API Name",
    minWidth: 300,
    maxWidth: 400,
    sortable: true,
  },
  {
    cellRendererFramework: EllipsisCellRenderer,
    field: "api_id",
    headerName: "API ID",
    sortable: true,
    minWidth: 300,
    maxWidth: 300,
  },
  {
    cellRendererFramework: EllipsisCellRenderer,
    field: "api_type",
    headerName: "API Type",
    minWidth: 100,
    maxWidth: 100,
  },
  {
    cellRendererFramework: EllipsisCellRenderer,
    field: "visibility",
    headerName: "Visibility",
    minWidth: 100,
    maxWidth: 100,
  },
  {
    cellRendererFramework: EllipsisCellRenderer,
    field: "publisher",
    headerName: "Publisher",
    minWidth: 100,
  },
];

  const bundleApiData =
    Array.isArray(selectedApiInfo) &&
    selectedApiInfo?.map((api) => ({
      api_name: api?.name || api?.apiName,
      api_id: api?.id || api?.apiId,
      api_type: getTypeOfApis(api?.type) || getTypeOfApis(api?.apiType),
      publisher: api?.publisher || api?.orgName,
      visibility: capitalizeVisibility(api?.visibility) || capitalizeVisibility(api?.apiVisibility),
    }));

    const handleGridReady = useCallback((gridParams) => {
      setGridApi(gridParams.api);
      gridParams.api.sizeColumnsToFit();
      gridParams.api.refreshCells({ force: true });
    }, []);
  return (
      <div>
        <Dialog
          title="API Details"
          className="bundle-apis-contracted"
          hideHeader
          isOpen={dialogOpen}
          onClose={() => setDialogOpen(false)}
        >
          <DialogHeader
            className="contracted-bundle-name"
            onClose={() => setDialogOpen(false)}
            title={bundleData}
          />
          <TableContainer
            id="ag-grid-container"
            className={classNames(
              "contracted-bundles-dialog",
              "ag-theme-balham"
            )}
          >

            <TableContent
              data-testid="table-content"
              className="contracted-dialog"
              ref={tableContentRef}
            >
              <AgGridReact
                columnDefs={columns}
                domLayout={TABLE_LAYOUT_OPTIONS.AUTO_HEIGHT}
                rowData={bundleApiData}
                rowHeight={50}
                style={{ width: "100%" }}
                ref={gridRef}
                onGridReady={handleGridReady}
                rowSelection="multiple"
                suppressRowClickSelection={true}
                suppressScrollOnNewData={true}
              />
            </TableContent>
          </TableContainer>
        </Dialog>
      </div>
  );
};

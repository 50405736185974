import types from "./types";
import { CALL_API } from "@cdkglobal/react-core-reduxapi";
import config from "../config/app.conf.json";
import axios from "axios";

const entityReqObj = {
  url: config.api.organizations_uri,
  method: "POST"
};

const generateGetEntityUrl = (url, name, domain, deleted, id, pageSize) => {
  let queryParams = ["status=approved", "isVerified=true"];
  if (name) queryParams.push(`name=${name}`);
  if (deleted) queryParams.push(`deleted=${deleted}`);
  if (domain) queryParams.push(`emailDomain=${domain}`);
  if (id) {
    queryParams = []
    queryParams.push(`orgIds=${id}`)
  }
  if (pageSize) queryParams.push(`pageSize=${pageSize}`);
  return `${url}?${queryParams.join("&")}`;
};

const getAgreementsStatusUrl = (url, orgId) => {
  url = url.replace("<orgId>", orgId);
  return url;
};

const generateGetAgreementUrl = (url, orgId, agreementType) => {
  url = url.replace("<orgId>", orgId);
  url = url.replace("<agreementType>", agreementType);
  return url;
};

const generateDeleteAgreementUrl = (url, orgId, agreementType) => {
  url = url.replace("<orgId>", orgId);
  url = url.replace("<agreementType>", agreementType);
  return url;
};

export const actions = {
  registerEntity: ({ accessToken, dataobject }) => ({
    [CALL_API]: {
      ...entityReqObj,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json"
      },
      data: {
        ...dataobject
      },
      types: [
        types.ENTITY_POST_REQUEST,
        types.ENTITY_POST_RECEIVE,
        types.ENTITY_POST_ERROR
      ]
    }
  }),
  searchEntity: ({ accessToken, name, domain, id, pageSize }) => ({
    [CALL_API]: {
      url: generateGetEntityUrl(config.api.organizations_uri, name, domain, null, id, pageSize),
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json"
      },
      types: [
        types.ENTITY_GET_REQUEST,
        types.ENTITY_GET_RECEIVE,
        types.ENTITY_GET_ERROR
      ]
    }
  }),
  searchDeletedEntity: ({ accessToken, name, domain, deleted }) => ({
    [CALL_API]: {
      url: generateGetEntityUrl(
        config.api.organizations_uri,
        name,
        domain,
        deleted
      ),
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json"
      },
      types: [
        types.DELETED_ENTITY_GET_REQUEST,
        types.DELETED_ENTITY_GET_RECEIVE,
        types.DELETED_ENTITY_GET_ERROR
      ]
    }
  }),
  searchEntityForUserActivation: ({ accessToken, name, domain, sourceId, id }) => ({
    [CALL_API]: {
      url: generateGetEntityUrl(config.api.organizations_uri, name, domain, null, id),
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json"
      },
      types: [
        types.ENTITY_GET_REQUEST_NEW,
        { type: types.ENTITY_GET_RECEIVE_NEW, sourceId: sourceId },
        types.ENTITY_GET_ERROR_NEW
      ]
    }
  }),
  updateFeatureGates: ({ accessToken, entity, data }) => {
    return dispatch => {
      dispatch({
        type: types.ENABLE_FEATURE_GATE_REQUEST,
        entity
      });
      return new Promise(resolve => {
        axios
          .put(
            `${config.api.organizations_uri}/${entity.id}/feature-gate`,
            data,
            {
              headers: {
                Authorization: "Bearer " + accessToken
              }
            }
          )
          .then(res => {
            const result = getResult(types.ENABLE_FEATURE_GATE_RECEIVE, {
              ...res.data,
              entity
            });
            dispatch(result);
            resolve(result);
          })
          .catch(error => {
            const result = getResult(
              types.ENABLE_FEATURE_GATE_ERROR,
              error.response || {}
            );
            dispatch(result);
            resolve(result);
          });
      });
    };
  },
  updateNameSpaces: ({ accessToken, entity, data }) => {
    return dispatch => {
      dispatch({
        type: types.ENABLE_NAMESPACE_REQUEST,
        entity
      });
      return new Promise(resolve => {
        axios
          .put(`${config.api.organizations_uri}/${entity.id}`, data, {
            headers: {
              Authorization: "Bearer " + accessToken
            }
          })
          .then(res => {
            const result = getResult(types.ENABLE_NAMESPACE_RECEIVE, {
              ...res.data,
              entity
            });
            dispatch(result);
            resolve(result);
          })
          .catch(error => {
            const result = getResult(
              types.ENABLE_NAMESPACE_ERROR,
              error.response || {}
            );
            dispatch(result);
            resolve(result);
          });
      });
    };
  },
  uploadAgreement: ({ accessToken, entity, formData }) => {
    return dispatch => {
      dispatch({
        type: types.ADD_AGREEMENT_REQUEST,
        entity
      });
      axios
        .post(`${config.api.upload_agreement}/${entity.id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + accessToken
          }
        })
        .then(res => {
          dispatch(
            getResult(types.ADD_AGREEMENT_RECEIVE, { ...res.data, entity })
          );
        })
        .catch(error => {
          if (error.response) {
            dispatch(getResult(types.ADD_AGREEMENT_ERROR, error.response));
          } else {
            dispatch(getResult(types.ADD_AGREEMENT_ERROR, {}));
          }
        });
    };
  },
  closeAgreementDialog: () => {
    return dispatch => {
      dispatch({
        type: types.CLOSE_AGREEMENT_DIALOG
      });
    };
  },
  getAgreementsDetailsAndOpenDialog: ({ accessToken, entity }) => {
    return dispatch => {
      dispatch({
        type: types.AGREEMENT_STATUS_REQUEST,
        entity
      });
      axios
        .get(
          getAgreementsStatusUrl(config.api.get_agreements_status, entity.id),
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken
            }
          }
        )
        .then(res => {
          dispatch(
            getResult(types.AGREEMENT_STATUS_RECEIVE, { ...res.data, entity })
          );
        })
        .catch(error => {
          if (error.response) {
            dispatch(getResult(types.AGREEMENT_STATUS_ERROR, error.response));
          } else {
            dispatch(getResult(types.AGREEMENT_STATUS_ERROR, {}));
          }
        });
    };
  },
  viewAgreement: ({ accessToken, entity, agreementType }) => {
    return dispatch => {
      dispatch({
        type: types.GET_AGREEMENT_REQUEST,
        entity
      });
      axios
        .get(
          generateGetAgreementUrl(
            config.api.get_agreement,
            entity.id,
            agreementType
          ),
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken
            }
          }
        )
        .then(res => {
          dispatch(
            getResult(types.GET_AGREEMENT_RECEIVE, { ...res.data, entity })
          );
        })
        .catch(error => {
          if (error.response) {
            dispatch(getResult(types.GET_AGREEMENT_ERROR, error.response));
          } else {
            dispatch(getResult(types.GET_AGREEMENT_ERROR, {}));
          }
        });
    };
  },
  deleteAgreement: ({ accessToken, entity, agreementType }) => {
    return dispatch => {
      dispatch({
        type: types.DELETE_AGREEMENT_REQUEST,
        entity
      });
      axios
        .delete(
          generateDeleteAgreementUrl(
            config.api.delete_agreement,
            entity.id,
            agreementType
          ),
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken
            }
          }
        )
        .then(res => {
          dispatch(
            getResult(types.DELETE_AGREEMENT_RECEIVE, { ...res.data, entity })
          );
        })
        .catch(error => {
          if (error.response) {
            dispatch(getResult(types.DELETE_AGREEMENT_ERROR, error.response));
          } else {
            dispatch(getResult(types.DELETE_AGREEMENT_ERROR, {}));
          }
        });
    };
  },
  replaceAgreement: ({ accessToken, entity, formData }) => {
    return dispatch => {
      dispatch({
        type: types.REPLACE_AGREEMENT_REQUEST,
        entity
      });
      axios
        .put(`${config.api.replace_agreement}/${entity.id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + accessToken
          }
        })
        .then(res => {
          dispatch(
            getResult(types.REPLACE_AGREEMENT_RECEIVE, { ...res.data, entity })
          );
        })
        .catch(error => {
          if (error.response) {
            dispatch(getResult(types.REPLACE_AGREEMENT_ERROR, error.response));
          } else {
            dispatch(getResult(types.REPLACE_AGREEMENT_ERROR, {}));
          }
        });
    };
  },
  deleteOrganization: ({ accessToken, entity }) => {
    return dispatch => {
      dispatch({
        type: types.DELETE_ORGANIZATION_REQUEST,
        entity
      });
      axios
        .delete(`${config.api.organizations_uri}/${entity}`, {
          headers: {
            Authorization: "Bearer " + accessToken
          }
        })
        .then(res => {
          dispatch({
            ...getResult(types.DELETE_ORGANIZATION_RECEIVE, { ...res.data }),
            entity
          });
        })
        .catch(error => {
          if (error.response) {
            dispatch(
              getResult(types.DELETE_ORGANIZATION_ERROR, error.response)
            );
          } else {
            dispatch(getResult(types.DELETE_ORGANIZATION_ERROR, {}));
          }
        });
    };
  },
  restoreOrganization: ({ accessToken, data }) => {
    return dispatch => {
      dispatch({
        type: types.RESTORE_ORGANIZATION_REQUEST,
        data
      });
      axios
        .put(`${config.api.organizations_uri}/restore`, data, {
          headers: {
            Authorization: "Bearer " + accessToken
          }
        })
        .then(res => {
          dispatch({
            ...getResult(types.RESTORE_ORGANIZATION_RECEIVE, { ...res.data }),
            data
          });
        })
        .catch(error => {
          if (error.response) {
            dispatch(
              getResult(types.RESTORE_ORGANIZATION_ERROR, error.response)
            );
          } else {
            dispatch(getResult(types.RESTORE_ORGANIZATION_ERROR, {}));
          }
        });
    };
  },
  updateEnhancedFlags: ({ accessToken, entity, data }) => {
    return dispatch => {
      dispatch({
        type: types.ENABLE_ENHANCED_FLAGS_REQUEST,
        entity
      });
      return new Promise(resolve => {
        axios
          .put(`${config.api.organizations_uri}/${entity.id}`, data, {
            headers: {
              Authorization: "Bearer " + accessToken
            }
          })
          .then(res => {
            const result = getResult(types.ENABLE_ENHANCED_FLAGS_RECEIVE, {
              ...res.data,
              entity
            });
            dispatch(result);
            resolve(result);
          })
          .catch(error => {
            const result = getResult(
              types.ENABLE_ENHANCED_FLAGS_ERROR,
              error || {}
            );
            dispatch(result);
            resolve(result);
          });
      });
    };
  },
  updateDefinedType: ({ accessToken, entity, data }) => {
    return dispatch => {
      dispatch({
        type: types.UPDATE_DEFINED_TYPE_REQUEST,
        entity
      });
      return new Promise(resolve => {
        axios
          .put(`${config.api.organizations_uri}/${entity.id}/fortellis-defined-type`, data, {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          })
          .then(res => {
            const result = getResult(types.UPDATE_DEFINED_TYPE_RECEIVE, {
              ...res.data,
              entity
            });
            dispatch(result);
            resolve(result);
          })
          .catch(error => {
            const result = getResult(
              types.UPDATE_DEFINED_TYPE_ERROR,
              error || {}
            );
            dispatch(result);
            resolve(result);
          });
      });
    };
  }
};

const getResult = (actionType, res) => {
  return {
    type: actionType,
    response: res
  };
};

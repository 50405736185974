import React from "react";
import { ThemeProvider } from "styled-components";
import AppToolbar from "./AppToolbar";
import { GlobalHeaderAdjust } from "@cdk-uip/react-global-header";
import { Fluid, FluidItem } from "@cdk-uip/react-fluid";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import { Route, Switch } from "react-router-dom";
import { Security, LoginCallback, SecureRoute } from "@okta/okta-react";
import { getAuthContext } from "@cdk-prod/fortellis-auth-context";
import { fortellisTheme } from "cdk-radial";

import Home from "./Home";
import PendingActivations from "../containers/PendingActivationsContainer";
import RegistrationMetrics from "../Reporting/registrationMetricsContainer";
import SubscriptionMangement from "../SubscriptionManagement/subscriptionsContainer";
import SubscriptionReports from "../SubscriptionReports/SubscriptionsReportContainer";
import EntityMangement from "../EntityManagement/entityContainer";
import RestoreOrg from "../EntityManagement/entityRestoreContainer";
import ApiAdmin from "../ApiGatewayAdmin/ApiAdminContainer";
import ApiInfo from "../ApiGatewayAdmin/ApiInfoContainer";
import ApiBundleCreate from "../ApiBundleAdmin/create";
import ApiBundleList from "../ApiBundleAdmin/ApiBundleList";
import ApiEditBundle from "../ApiBundleAdmin/ApiEditBundle";
import ApiBundleContainer from '../ApiBundleAdmin/ApiBundleContainer';
import AppMetrics from "../AppMetrics/AppMetricsContainer";
import EntityApproval from "../EntityApproval/EntityApprovalContainer";
import BulkUserUpload from "../BulkUserUpload/bulkUserUploadContainer";
import ExportEntities from "../ExportEntities/ExportEntitiesContainer";
import BulkSubscriptions from "../BulkSubscriptions/BulkSubscriptionsContainer";
import Agreements from "../Agreements/Agreements";
import FortellisDeveloperTerms from "../Agreements/fortellisDeveloperTermsContainer";
import TnCUploader from "../Agreements/TnCEditor/tncUploaderContainer";
import SignedUpUsers from "../SignedUpUsers/SignedUpUsersContainer";
import ApprovedUsers from "../ApprovedUsers/ApprovedUsersContainer";
import ApiUsage from "../ApiUsage/ApiUsageContainer";
import UserProfile from "../UserProfileManager/UserProfileContainer";
import MonetizationReports from "../MonetizationReports/MonetizationReportContainer";
import BulkSubscriptionsList from "../BulkSubscriptions/BulkSubscriptionsList";
import CertificationManagementListContainer from "../CertificationMangement/CertificationManagementList";
import AppDetailsContainer from "../CertificationMangement/AppDetails";
import AuditReports from "../AuditReports/AuditReportsContainer";
import ApiIntegrationManagement from "../ApiIntegrationManagement/ApiIntegrationManagementContainer";
import LastTransactions from "../LastTransactions/LastTransactionsContainer";
import FakeLogin from "./Login/FakeLogin";
import SearchComponent from "../UsageReport/SearchComponent";
import PaymentCodeManagementContainer from "../PaymentCodeManagement/PaymentCodeManagementContainer";
import PaymentCodeCreateContainer from '../PaymentCodeManagement/PaymentCodeCreateContainer';
import config from "../config/app.conf.json";
import ManageContractedApis from "../EntityManagement/ManageContractApis";

const env = {
  issuer: process.env.REACT_APP_AUTH_ISSUER,
  clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
  redirectUri: `${window.location.origin}/login/callback`,
  scopes: ["openid", "profile", "email"],
  pkce: false
};

const App = props => {
  return (
    <ThemeProvider theme={fortellisTheme}>
      <MuiThemeProvider>
        <Security
          oktaAuth={props.oktaAuth}
          restoreOriginalUri={props.restoreOriginalUri}
        >
          <AppToolbar />
          <GlobalHeaderAdjust fixed>
            <Fluid
              className={
                "c-layout-screen c-layout-screen_wrapper mdc-toolbar-fixed-adjust"
              }
              halign="center"
              valign="middle"
            >
              <FluidItem className={"c-layout-screen__content"}>
                <Switch>
                  <Route
                    exact
                    path={"/login/callback"}
                    component={LoginCallback}
                  />
                  <SecureRoute path="/" exact component={Home} />

                  <SecureRoute
                    path="/userprofile/:userEmail"
                    exact
                    component={UserProfile}
                  />
                  <SecureRoute
                    path="/pending"
                    exact
                    component={PendingActivations}
                  />
                  <SecureRoute
                    path="/signed-up-users"
                    exact
                    component={SignedUpUsers}
                  />
                  <SecureRoute
                    path="/approved-users"
                    exact
                    component={ApprovedUsers}
                  />
                  <SecureRoute
                    path="/metrics"
                    exact
                    component={RegistrationMetrics}
                  />
                  <SecureRoute
                    path="/subscriptions"
                    exact
                    component={SubscriptionMangement}
                  />
                  <SecureRoute path="/api-admin" exact component={ApiAdmin} />
                  <SecureRoute path="/api-admin/:id" component={ApiInfo} />
                  <SecureRoute
                    path="/bundle-management"
                    exact
                    component={ApiBundleContainer}
                  />
                  <SecureRoute
                    path="/pending-orgs"
                    exact
                    component={EntityApproval}
                  />
                  <SecureRoute
                    path="/org-management"
                    exact
                    component={EntityMangement}
                  />
                   <SecureRoute
                   path="/org-management/:orgId/contracts"
                    exact
                    component={ManageContractedApis}
                  />
                  <SecureRoute
                    path="/restore-orgs"
                    exact
                    component={RestoreOrg}
                  />
                  <SecureRoute path="/app-metrics" exact component={AppMetrics} />
                  <SecureRoute
                    path="/bulk-subscriptions"
                    exact
                    component={BulkSubscriptions}
                  />
                  <SecureRoute
                    path="/bulk-subscriptions/jobs"
                    exact
                    component={BulkSubscriptionsList}
                  />
                   <SecureRoute
                    path="/certification-management"
                    exact
                    component={CertificationManagementListContainer}
                  />
                  <SecureRoute
                    path="/certification-management/app/:id"
                    exact
                    component={AppDetailsContainer}
                  />
                  <SecureRoute
                    path="/bulk-user-upload"
                    exact
                    component={BulkUserUpload}
                  />
                  <SecureRoute
                    path="/export-orgs"
                    exact
                    component={ExportEntities}
                  />
                  <SecureRoute path="/agreements" exact component={Agreements} />
                  <SecureRoute
                    path="/agreements/fortellis-developer-terms"
                    exact
                    component={FortellisDeveloperTerms}
                  />
                  <SecureRoute
                    path="/agreements/fortellis-developer-terms/tncUploader"
                    exact
                    component={TnCUploader}
                  />
                  <SecureRoute path="/api-usage" exact component={ApiUsage} />
                  <SecureRoute
                    path="/subscription-reports"
                    exact
                    component={SubscriptionReports}
                  />
                  <SecureRoute
                    path="/monetization-reports"
                    exact
                    component={MonetizationReports}
                  />
                  <SecureRoute
                    path="/usage-reports"
                    exact
                    component={SearchComponent}
                  />
                  <SecureRoute
                    path="/audit-reports"
                    exact
                    component={AuditReports}
                  />
                  <SecureRoute
                    path="/api-integration-management"
                    exact
                    component={ApiIntegrationManagement}
                  />
                  <SecureRoute
                    path="/last-transactions-report"
                    exact
                    component={LastTransactions}
                  />
                  <SecureRoute
                    path="/payment-codes"
                    exact
                    component={PaymentCodeManagementContainer}
                  />
                  <SecureRoute
                    path="/payment-codes/create"
                    exact
                    component={PaymentCodeCreateContainer}
                  />
                  {process.env.NODE_ENV === "development" ? (
                    <Route path="/fake-login" component={FakeLogin} />
                  ) : (
                    false
                  )}
                </Switch>
              </FluidItem>
            </Fluid>
          </GlobalHeaderAdjust>
        </Security>
      </MuiThemeProvider>
    </ThemeProvider>
  );
};

export default getAuthContext({ WrappedComponent: App, env });

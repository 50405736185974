import React, { Component } from "react";
import styled from 'styled-components';
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { withAuth } from "@cdk-prod/fortellis-auth-context";
import { actions } from "./entityActions";
import { CircularProgress } from "@cdk-uip/react-circular-progress";
import { Headline } from "@cdk-uip/react-typography";

// UI COMPONENTS
import {
  IconAssignment,
  IconBuildingCorporateOffice,
  IconDelete,
  IconDropOff,
  IconFlag,
  IconRepairOrder,
  IconSales,
  IconUser,
  PrimaryButton,
  SecondaryButton
} from 'cdk-radial';

import { Elevation } from "@cdk-uip/react-elevation";
import {
  Card,
  CardHeader,
  CardTitle,
  CardSubtitle,
  CardText
} from "@cdk-uip/react-card";
import { Select } from "@cdk-uip/react-select";
import { TextField } from "@cdk-uip/react-text-field";
import { Fluid, FluidItem } from "@cdk-uip/react-fluid";
import { Icon } from "@cdk-uip/react-icon";
import { LoadingButton } from "../components/LoadingButton";
import { ButtonIcon } from "@cdk-uip/react-button";
import copy from "copy-to-clipboard";
import { TemporarySnackbar } from "@cdk-uip/react-snackbar";
import { LayoutGrid, LayoutGridCell } from "@cdk-uip/react-layout-grid";
import { AgreementsManagement } from "./AgreementsManagement";
import FeatureGatesDialog from "./FeatureGatesDialog";
import NamespaceDialog from "./NamespaceDialog";
import DeleteOrganizationDialog from "./DeleteOrganizationDialog";
import EnhancedFlagsDialog from "./EnhancedFlagsDialog";

import config from "../config/app.conf.json";
import FortellisDefinedTypeDialog from "./FortellisDefinedTypeDialog";

const OrgActionSecondaryButton = styled(SecondaryButton)`
    margin: 0.25rem;
`;
const OrgActionPrimaryButton = styled(PrimaryButton)`
    margin: 0.25rem;
`;
const OrgActionDangerButton = styled(OrgActionSecondaryButton)`
    background-color: ${({ theme }) => theme.color.secondary.cherryBombRed[50].value} !important;
    color: ${({ theme }) => theme.color.secondary.cherryBombRed[600].value} !important;

    :hover {
        background-color: ${({ theme }) => theme.color.secondary.cherryBombRed[100].value} !important;
    }
    :active {
        background-color: ${({ theme }) => theme.color.secondary.cherryBombRed[200].value} !important;
    }
`;
const DangerIconDelete = styled(IconDelete)`
    fill: ${({ theme }) => theme.color.secondary.cherryBombRed[600].value} !important;
`

const DIALOGS = {
  // agreement management dialog is managed in redux
  FEATURE_GATES: 'feature_gates',
  NAMESPACES: 'namespaces',
  DEFINED_TYPE: 'defined_type',
  ENHANCED_FLAGS: 'enhanced_flags',
  DELETE_ORG: 'delete_org'
};

const NAME_TYPE = "name";
const DOMAIN_TYPE = "domain";
const ID = 'ID'
const UNAVALIABLE = "n/a";

const DEFINED_TYPE_FIXED_TYPES = ["department"];

const ORG_RESULTS_LIMIT = 100;

class EntitySearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchString: "",
      searchType: NAME_TYPE,
      searchInProgress: false,
      lastSearch: "",
      snack: false,
      isSearchDisabled: true,
      orgSearchData: null,
      verifiedOverrides: {},

      openedDialog: null,
      entityToEdit: null,

      showFeatureGatesDialog: false,
      showNameSpaceDialog: false,
      showEnhancedFlagsDialog: false,
      showDefinedTypeDialog: false,
      showDeleteOrganizationDialog: false,

      entityToEditNameSpace: null,
      entityToEditFeatureGates: null,
      entityToEditEnhancedFlags: null,
      entityToEditDefinedType: null
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      entityManagement,
      entityManagement: {
        searchData,
        searchError,
        verifyEntityId,
        verifyIsVerified
      }
    } = this.props;
    if (prevProps.entityManagement !== entityManagement) {
      if (
        prevProps.entityManagement.searchData !== entityManagement.searchData
      ) {
        if (searchData.organizations.length > 0) {
          this.setState({
            searchString: "",
            lastSearch: prevState.searchString,
            searchInProgress: false,
            isSearchDisabled: true,
            orgSearchData: entityManagement.searchData
          });
        } else {
          this.setState({
            lastSearch: prevState.searchString,
            searchInProgress: false
          });
        }
      } else if (searchError) {
        this.setState({
          searchInProgress: false
        });
      }
      if (
        prevProps.entityManagement.verifyEntityId !== verifyEntityId ||
        prevProps.entityManagement.verifyIsVerified !== verifyIsVerified
      ) {
        // a verify request completed
        if (verifyIsVerified) {
          this.updateSnack("Organization verified");
        } else {
          this.updateSnack("Organization unverified");
        }
        this.setState(prevState => ({
          verifiedOverrides: {
            ...prevState.verifiedOverrides,
            [verifyEntityId]: verifyIsVerified
          }
        }));
      }
    }
  }

  onInputChange = (change, field) => {
    this.setState({
      [field]: change
    });
  };

  onSubmit = () => {
    const { searchString, searchType } = this.state;
    const {
      auth: { accessToken }
    } = this.props;
    let trimmedSearch = searchString.trim();
    if (searchType.length) {
      if (searchType === NAME_TYPE) {
        this.props.searchEntity({
          accessToken,
          name: trimmedSearch,
          pageSize: ORG_RESULTS_LIMIT
        });
        this.setState({ searchInProgress: true });
      } else if (searchType === DOMAIN_TYPE) {
        this.props.searchEntity({
          accessToken,
          domain: trimmedSearch,
          pageSize: ORG_RESULTS_LIMIT
        });
        this.setState({ searchInProgress: true });
      } else if (searchType === ID) {
        this.props.searchEntity({
          accessToken,
          id: trimmedSearch
        });
        this.setState({ searchInProgress: true });
      }
    }
  };

  uploadAgreement = (entity, agreementType, event) => {
    const fileToUpload = event.target.files[0];
    if (fileToUpload) {
      const {
        auth: { accessToken }
      } = this.props;
      let formData = new FormData();
      formData.append("agreementType", agreementType);
      formData.append("uploadedFile", fileToUpload);
      this.props.uploadAgreement({ accessToken, entity, formData });
    }
  };

  viewAgreement = (entity, agreementType) => {
    const {
      auth: { accessToken }
    } = this.props;
    this.props.viewAgreement({ accessToken, entity, agreementType });
  };

  deleteAgreement = (entity, agreementType) => {
    const {
      auth: { accessToken }
    } = this.props;
    this.props.deleteAgreement({ accessToken, entity, agreementType });
  };

  deleteOrganization = entity => {
    const {
      auth: { accessToken }
    } = this.props;
    this.props.deleteOrganization({ accessToken, entity });
  };

  replaceAgreement = (entity, agreementType, event) => {
    const fileToUpload = event.target.files[0];
    if (fileToUpload) {
      const {
        auth: { accessToken }
      } = this.props;
      let formData = new FormData();
      formData.append("agreementType", agreementType);
      formData.append("uploadedFile", fileToUpload);
      this.props.replaceAgreement({ accessToken, entity, formData });
    }
  };

  updateSnack = text => {
    this.setState({
      snack: text
    });
  };

  openAgreementManagement = entity => {
    this.closeDialog();
    const {
      auth: { accessToken }
    } = this.props;
    this.props.getAgreementsDetailsAndOpenDialog({ accessToken, entity });
  };

  openDialog = (dialog, entity) => {
    this.setState({
      openedDialog: dialog,
      entityToEdit: entity
    })
  };

  closeDialog = () => {
    this.setState({
      openedDialog: null,
      entityToEdit: null
    });
  };

  openFeatureGatesDialog = entity => this.openDialog(DIALOGS.FEATURE_GATES, entity);
  openNameSpaceDialog = entity => this.openDialog(DIALOGS.NAMESPACES, entity);
  openDeleteOrganizationDialog = entity => this.openDialog(DIALOGS.DELETE_ORG, entity);
  openEnhancedFlagsDialog = entity => this.openDialog(DIALOGS.ENHANCED_FLAGS, entity);
  openDefinedTypeDialog = entity => this.openDialog(DIALOGS.DEFINED_TYPE, entity);

  assumeAdminRole = (entity) => {
    function setCookie(entityId) {
      const d = new Date();
      d.setTime(d.getTime() + 30 * 24 * 60 * 60 * 1000);
      let expires = "expires=" + d.toUTCString();
      document.cookie = `entityId=${entityId};${expires};path=/;domain=.fortellis.io;secure;samesite=none`;
    }
    setCookie(entity.id);
    const devNetAppBaseUrl =
      config?.fortellis?.developer_network_base_uri ||
      "https://developer.fortellis.io";
    window.location.assign(`${devNetAppBaseUrl}/developer-account`);
  };

  updateOrgData = (entity, updates = {}, bumpVersion = false) => {
    const orgData = JSON.parse(JSON.stringify(this.state.orgSearchData));
    const organizationEntity = orgData.organizations.find(
      f => f.id === entity.id
    );
    for (const [key, value] of Object.entries(updates)) {
      organizationEntity[key] = value;
    }
    if (bumpVersion) {
      organizationEntity.version += 1;
    }
    this.setState({ orgSearchData: orgData });
  };

  updateFeatureGates = async (entity, featureGates) => {
    const {
      auth: { accessToken }
    } = this.props;
    try {
      const data = featureGates ? JSON.parse(featureGates) : undefined;
      const { response } = await this.props.updateFeatureGates({
        accessToken,
        entity,
        data
      });
      console.log(response);
      if (response.featureGates !== undefined) {
        this.updateOrgData(entity, { featureGates: response.featureGates });
      } else if (response.message) {
        throw new Error(response.message);
      }
    } catch (error) {
      if (error instanceof SyntaxError) {
        throw new Error("Invalid JSON syntax");
      } else {
        throw error;
      }
    }
  };

  updateNameSpaces = async (entity, namespaces) => {
    const {
      auth: { accessToken }
    } = this.props;
    try {
      const orgNamespace = namespaces ? JSON.parse(namespaces) : undefined;
      const data = {
        namespaces: orgNamespace,
        version: entity && entity.version
      };
      const { response } = await this.props.updateNameSpaces({
        accessToken,
        entity,
        data
      });

      if (response.namespaces !== undefined) {
        this.updateOrgData(entity, { namespaces: response.namespaces }, true);
      } else if (response.message) {
        throw new Error(response.message);
      }
    } catch (error) {
      if (error instanceof SyntaxError) {
        throw new Error("Invalid Array of string Syntax");
      } else {
        throw error;
      }
    }
  };

  updateEnhancedFlags = async (entity, enhancedFlags) => {
    const {
      auth: { accessToken }
    } = this.props;
    try {
      const data = {
        enhancedFlags: enhancedFlags,
        version: entity && entity.version
      };
      const { response } = await this.props.updateEnhancedFlags({
        accessToken,
        entity,
        data
      });
      this.closeDialog();
      if (response.enhancedFlags !== undefined) {
        this.updateOrgData(entity, {
          enhancedFlags: JSON.stringify(response.enhancedFlags),
          version: response.version
        });
      }
    } catch (error) {
        throw error;
    }
  };

  updateDefinedType = async (entity, fortellisDefinedType) => {
    const {
      auth: { accessToken }
    } = this.props;
    const data = { fortellisDefinedType };
    const { response } = await this.props.updateDefinedType({
      accessToken,
      entity,
      data
    });
    this.closeDialog();
    if (response.fortellisDefinedType !== undefined) {
      this.updateOrgData(entity, {
        fortellisDefinedType: response.fortellisDefinedType
      });
    }
  };

  closeAgreementDialog = () => {
    this.props.closeAgreementDialog();
  };

  render() {
    let searchData = this.state.orgSearchData;

    const {
      searchInProgress,
      lastSearch,
      searchType,
      searchString,
      isSearchDisabled
    } = this.state;

    return (
      <React.Fragment>
        {searchInProgress ||
        this.props.entityManagement.showUploadProgress ||
        this.props.entityManagement.showRetrieveProgress ||
        this.props.entityManagement.showDeleteProgress ||
        this.props.entityManagement.showReplaceProgress ? (
          <div className="c-circular-progress--overlay-wrapper">
            {this.props.entityManagement.showUploadProgress ||
            this.props.entityManagement.showRetrieveProgress ||
            this.props.entityManagement.showDeleteProgress ||
            this.props.entityManagement.showReplaceProgress ? (
              <Headline>{this.props.entityManagement.progressMessage}</Headline>
            ) : (
              <Headline>
                Searching...
                <br />
                Showing related Organizations
                <br />
                Please wait...
              </Headline>
            )}
            <CircularProgress className="c-circular-progress--overlay" />
          </div>
        ) : (
          false
        )}
        <Elevation z={10} className={"c-dash-card__wrapper"}>
          <Card className="c-dash-card c-form-subscriptions c-form-subscriptions--start">
            <CardHeader>
              <CardTitle large>Search Organizations</CardTitle>
              <CardSubtitle>Search by name or email domain or entity id.</CardSubtitle>
            </CardHeader>
            <CardText>
              <Fluid valign="middle">
                <FluidItem fill>
                  <TextField
                    id="entitySearch"
                    name="entitySearch"
                    className="c-search-input--field"
                    spellCheck={false}
                    type="string"
                    inputMode="text"
                    value={searchString}
                    onChange={e => {
                      this.onInputChange(e.target.value, "searchString");
                      this.setState({
                        isSearchDisabled: e.target.value.trim().length < 3
                      });
                    }}
                    fullWidth
                  />
                </FluidItem>
                <FluidItem>
                  <Select
                    label="Search By:"
                    className="c-search-input--type"
                    value={searchType}
                    onChange={e =>
                      this.onInputChange(e.target.value, "searchType")
                    }
                    allowEmpty={false}
                    required
                  >
                    <option value={NAME_TYPE}>Organization Name</option>
                    <option value={DOMAIN_TYPE}>Email Domain</option>
                    <option value={ID}>Organization ID</option>
                  </Select>
                </FluidItem>
                <FluidItem>
                  <LoadingButton
                    className="loadingButton-leftPadding c-search--submit"
                    raised
                    primary
                    dense
                    onClick={this.onSubmit}
                    isLoading={searchInProgress}
                    type="submit"
                    disabled={isSearchDisabled}
                    loadingValue={"Searching..."}
                  >
                    <ButtonIcon>arrow_forward</ButtonIcon>
                    Search
                  </LoadingButton>
                </FluidItem>
              </Fluid>
              <div className="hint">
                {searchType === DOMAIN_TYPE && <span>Enter full or partial email addresses or domains.</span>}
                {searchType === NAME_TYPE && <span>Enter full or partial names.</span>}
                {searchType === ID && <span>Enter Organization Id.</span>}
              </div>
            </CardText>
            {searchData ? (
              <CardText>
                <h2>Result Returned For: "{lastSearch}"</h2>
                {!!searchData.organizations &&
                searchData.organizations.length > 0 ? (
                  <div className="c-entity-search--container">
                    {searchData.organizations.map(entity => (
                      <EntityInformation
                        entity={entity}
                        key={entity.id}
                        updateSnack={this.updateSnack}
                        openAgreementManagement={this.openAgreementManagement}
                        editFeatureGates={this.openFeatureGatesDialog}
                        editNamespace={this.openNameSpaceDialog}
                        deleteOrganization={this.openDeleteOrganizationDialog}
                        editEnhancedFlags={this.openEnhancedFlagsDialog}
                        editDefinedType={this.openDefinedTypeDialog}
                        assumeAdminRole={this.assumeAdminRole}
                      />
                    ))}
                  </div>
                ) : (
                  <div className="cdk-entity-search--result">
                    <h3>{`${lastSearch} not found`}</h3>
                  </div>
                )}
              </CardText>
            ) : (
              ""
            )}
          </Card>
        </Elevation>
        <AgreementsManagement
          open={!!this.props.entityManagement.showAgreementDialog}
          entity={
            this.props.entityManagement.agreementRequestForEntity
              ? this.props.entityManagement.agreementRequestForEntity
              : {}
          }
          closeAgreementDialog={this.closeAgreementDialog}
          uploadAgreement={this.uploadAgreement}
          viewAgreement={this.viewAgreement}
          deleteAgreement={this.deleteAgreement}
          replaceAgreement={this.replaceAgreement}
          shouldShow={this.props.entityManagement.shouldShow}
          snackbarMessage={this.props.entityManagement.snackbarMessage}
        />
        <FeatureGatesDialog
          open={this.state.openedDialog === DIALOGS.FEATURE_GATES}
          entity={this.state.entityToEdit}
          saveChanges={this.updateFeatureGates}
          onClose={this.closeDialog}
        />
        <NamespaceDialog
          open={this.state.openedDialog === DIALOGS.NAMESPACES}
          entity={this.state.entityToEdit}
          saveChanges={this.updateNameSpaces}
          onClose={this.closeDialog}
        />
        <DeleteOrganizationDialog
          open={this.state.openedDialog === DIALOGS.DELETE_ORG}
          entity={this.state.entityToEdit}
          onClose={this.closeDialog}
          deleteOrganization={this.deleteOrganization}
        />
        {this.state.openedDialog === DIALOGS.ENHANCED_FLAGS &&
          <EnhancedFlagsDialog
            open={this.state.openedDialog === DIALOGS.ENHANCED_FLAGS}
            entity={this.state.entityToEdit}
            saveChanges={this.updateEnhancedFlags}
            onClose={this.closeDialog}
          />
        }
        {this.state.openedDialog === DIALOGS.DEFINED_TYPE &&
          <FortellisDefinedTypeDialog
            open={this.state.openedDialog === DIALOGS.DEFINED_TYPE}
            entity={this.state.entityToEdit}
            saveChanges={this.updateDefinedType}
            onClose={this.closeDialog}
          />
        }
        <TemporarySnackbar
          show={this.state.snack.length}
          message={this.state.snack}
          onClose={() => this.setState({ snack: "" })}
          timeout={1000}
        />
      </React.Fragment>
    );
  }
}

const EntityInformation = ({
  entity,
  updateSnack,
  openAgreementManagement,
  editFeatureGates,
  editNamespace,
  deleteOrganization,
  editEnhancedFlags,
  editDefinedType,
  assumeAdminRole
}) => {
  const copyToClipboard = id => {
    let result = copy(id);
    if (result) {
      updateSnack("Copied Organization ID to Clipboard");
    }
  };
  const generateAddress = address => {
    const {
      street = "",
      postalCode = "",
      city = "",
      region = "",
      countryCode = ""
    } = address;
    return `${street}, ${postalCode} ${city} ${region} ${countryCode}`;
  };
  const history = useHistory();
 const manageContracted = () => {
    history.push({
      pathname: `/org-management/${entity.id}/contracts`
    });
  };

  return (
    <div className="c-entity-search--result">
      <div className="c-entity-search--result-title">
        <h2>{entity.name}</h2>
        <Icon
          onClick={() => copyToClipboard(entity.id)}
          title="Copy Organization ID"
        >
          content_copy
        </Icon>
      </div>

      <ul className="c-entity-search--result-items">
        <LayoutGrid nested className={"c-layout-section__home-grid"}>
          <LayoutGridCell
            span={8}
            className={
              "c-layout-section__home-grid-cell c-layout-section__home-grid-cell--navigational"
            }
          >
            <EntityListItem
              id={entity.id}
              text={entity.id}
              title="Organization ID"
            />
            {entity && entity.emailDomains && (
              <EntityListItem
                text={entity.emailDomains}
                title="Email Domains"
              />
            )}

            <EntityListItem text={entity.phone} title="Phone Number" />
            <EntityListItem text={entity.website} title="Website" />
            <EntityListItem
              text={
                entity.address ? generateAddress(entity.address) : undefined
              }
              title="Address"
            />
            <EntityListItem text={entity.belongsTo} title="Belongs to" />
            <EntityListItem text={entity.fortellisDefinedType} title="Fortellis type" />
          </LayoutGridCell>
          <LayoutGridCell>
            <OrgActionPrimaryButton
              text="Assume Org Role"
              icon={<IconDropOff />}
              onClick={() => assumeAdminRole(entity)}
            />
            <OrgActionDangerButton
              onClick={() => deleteOrganization(entity.id)}
              text="Delete"
              icon={<DangerIconDelete />}
            />
            <OrgActionSecondaryButton
              text="Agreements"
              icon={<IconAssignment />}
              onClick={() => openAgreementManagement(entity)}
            />
            <OrgActionSecondaryButton
              text="Contracted APIs"
              icon={<IconSales />}
              onClick={() => manageContracted()}
            />
            <OrgActionSecondaryButton
              text="Enhanced Flags"
              icon={<IconFlag />}
              onClick={() => editEnhancedFlags(entity)}
            />
            <OrgActionSecondaryButton
              text="Feature Gates"
              icon={<IconRepairOrder />}
              onClick={() => editFeatureGates(entity)}
            />
            <OrgActionSecondaryButton
              text="Namespaces"
              icon={<IconUser />}
              onClick={() => editNamespace(entity)}
            />
            <OrgActionSecondaryButton
              text="Org Type"
              icon={<IconBuildingCorporateOffice />}
              onClick={() => editDefinedType(entity)}
              isDisabled={DEFINED_TYPE_FIXED_TYPES.includes(
                entity.type?.toLowerCase()
              )}
            />

          </LayoutGridCell>
        </LayoutGrid>
      </ul>
    </div>
  );
};

const EntityListItem = ({ text, title, id }) => (
  <li className="c-search-list-item">
    <Fluid halign="left">
      <div className="c-search-list-item__title">{title}</div>
      <div className="c-search-list-item__divider">:</div>
      <div className="c-search-list-item__text" id={id}>
        {text ? text : UNAVALIABLE}
      </div>
    </Fluid>
  </li>
);

export default connect(
  state => ({
    entityManagement: state.entityManagement
  }),
  actions
)(withAuth(EntitySearch));

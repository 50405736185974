import { Component } from "react";
import saveAs from "file-saver";

import { withAuth } from "@cdk-prod/fortellis-auth-context";
import {
  Card,
  CardHeader,
  CardText,
  CardTitle,
  CardSubtitle,
  CardActions
} from "@cdk-uip/react-card";
import { Elevation } from "@cdk-uip/react-elevation";
import { LayoutGrid, LayoutGridCell } from "@cdk-uip/react-layout-grid";
import { Select } from "@cdk-uip/react-select";
import { StatusIndicatorInline } from "@cdk-uip/react-status-indicator";
import { CircularProgress } from "@cdk-uip/react-circular-progress";
import { Button, BUTTON_VARIANTS, IconEdit, IconFileDownload, IconCheck, TOAST_VARIANTS } from "cdk-radial";

import { ACTIVATION_MODELS, ACTIVATION_TEXT } from "./constants";
import { formatStatus, typeForStatus } from "./utils";
import withNavigationToApiAdmin from "./WithNavigationToApiAdmin";
import SpecFileView from "./SpecFileView";
import { ToastNotification } from "./ToastNotification";
import ApiEditDialog from "./ApiEditDialogRadial";
import NotesEditor from "./NotesEditor";
import { Confirm } from "../components/Confirm";

class ApiInfo extends Component {
  //// Constructor ////

  constructor(props) {
    super(props);
    this.state = {
      realApiId: "",

      zSpec: 10,
      zTitle: 10,
      zContact: 10,
      zSpecifics: 10,

      notesEditorOpen: false,
      notes: "",

      envsAvailable: [],
      envSelected: null,
      envSelectedValue: null,
      instancesAvailable: [],
      instanceSelected: null,
      instanceSelectedValue: null,

      specFile: "",
      specFileLines: ["No spec available"],

      approveConfirmationOpen: false
    };
  }

  //// Lifecycle ////

  componentDidMount() {
    const {
      apiInfo: { api },
      match: { params }
    } = this.props;

    const realApiId = api ? api.id : params.id;

    // regardless of whether we have an api from the parent page,
    // we need to download the api with expand=true on to get the specs
    this.fetchApi(realApiId);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      apiInfo: { fetchingApi, awaitingResponse, apiEditResponse, apiEditError, api }
    } = this.props;

    if (
      prevProps.apiInfo.awaitingResponse &&
      !awaitingResponse &&
      !apiEditError
    ) {
      // just finished editing (search info or notes)
      // either way, close the notes editor
      this.closeNotesEditor(this.state.newNotes || api.notes);
    }

    if (prevProps.apiInfo.fetchingApi && !fetchingApi) {
      // re-download finished
      this.loadEnvsAndInstances();
      this.loadNotes();
    }

    if (prevState.envSelected !== this.state.envSelected) {
      this.updateInstances();
    }
    if (prevState.instanceSelected !== this.state.instanceSelected) {
      this.updateSpec();
    }

    if (apiEditResponse && prevProps.apiInfo.apiEditResponse !== apiEditResponse && !awaitingResponse) {
      this.showToastNotif('API update successful', TOAST_VARIANTS.POSITIVE);
      this.fetchApi(api.id);
    }
    if (apiEditError && prevProps.apiEditError !== apiEditError && !awaitingResponse) {
      this.showToastNotif('API update unsuccessful');
    }
  }

  fetchApi = id => {
    const {
      auth: { accessToken }
    } = this.props;
    this.props.fetchApi({
      accessToken,
      id,
      expand: true
    });
  };

  loadEnvsAndInstances = () => {
    const {
      apiInfo: { api }
    } = this.props;
    // select the first environment that has instances
    if (api) {
      let stateUpdate = {
        envsAvailable: api.environments
      };
      for (const env of api.environments) {
        if (env.instances && env.instances.length) {
          stateUpdate.envSelected = env;
          stateUpdate.envSelectedValue = env.name;
          stateUpdate.instancesAvailable = env.instances;
          stateUpdate.instanceSelected = env.instances[0];
          stateUpdate.instanceSelectedValue = env.instances[0].id;
          break;
        }
      }
      this.setState(stateUpdate);
    }
  };

  updateInstances = () => {
    const {
      apiInfo: { api }
    } = this.props;

    if (api) {
      const selectedEnv = this.state.envSelected;
      if (selectedEnv) {
        const instancesAvailable = selectedEnv.instances;
        const instanceSelected = instancesAvailable[0] || null;
        this.setState({
          instancesAvailable,
          instanceSelected,
          instanceSelectedValue: instanceSelected ? instanceSelected.id : null
        });
      }
    }
  };

  setSelectedEnv = e => {
    const envSelected =
      this.state.envsAvailable.find(env => env.name === e.target.value) || null;
    this.setState({
      envSelected,
      envSelectedValue: e.target.value || null
    });
  };

  setSelectedInstance = e => {
    const instanceSelected =
      this.state.instancesAvailable.find(inst => inst.id === e.target.value) ||
      null;
    this.setState({
      instanceSelected,
      instanceSelectedValue: e.target.value || null
    });
  };

  updateApiMetadata = async (
    apiId,
    featured,
    weight,
    catalogs,
    systemSettings
  ) => {
    const {
      auth: { accessToken }
    } = this.props;
    return this.props
      .updateApi({
        accessToken,
        apiId,
        featured,
        weight,
        catalogs,
        systemSettings
      })
      .catch(err => console.error(err));
  };

  updateSpec = () => {
    if (this.state.instanceSelected && this.state.instanceSelected.spec) {
      // separate out lines in spec file (for preview card)
      const specFile = JSON.stringify(
        this.state.instanceSelected.spec,
        null,
        2
      );
      this.setState({
        specFile,
        specFileLines: specFile.split("\n")
      });
    } else {
      this.setState({
        specFileLines: ["No spec available"]
      });
    }
  };

  loadNotes = () => {
    const {
      apiInfo: { api }
    } = this.props;
    this.setState({
      notes: api.notes || ""
    });
  };

  openApproveConfirmation = () => {
    this.setState({
      approveConfirmationOpen: true
    });
  }

  approveApi = () => {
    const {
      auth: { accessToken },
      apiInfo: { api }
    } = this.props;
    return this.props
      .updateApi({
        accessToken,
        apiId: api.id,
        isPublicListingApproved: true
      })
      .catch(console.error)
      .finally(() => {
        this.setState({
          approveConfirmationOpen: false
        });
      });
  }

  /// Search info edit dialog ///

  openEditDialog = () => {
    this.setState({
      apiEditDialogOpen: true
    });
  };

  closeEditDialog = () => {
    this.setState({
      apiEditDialogOpen: false
    });
  };

  closeEditDialogFromSave = () => {
    const {
      apiInfo: { api }
    } = this.props;

    this.setState({
      apiEditDialogOpen: false
    });
    this.props.clearAfterApiUpdate();
    this.fetchApi(api.id);
  };

  //// Notes editor pane ////

  openNotesEditor = () => {
    const api = this.props.apiInfo.api;
    let notes;

    if (this.state.notes) {
      notes = this.state.notes;
    } else {
      notes = api.notes;
    }
    this.setState({
      notesEditorOpen: true,
      notes: notes
    });
  };

  saveAndCloseNotesEditor = notes => {
    this.setState({
      newNotes: notes
    });
    const {
      auth: { accessToken },
      apiInfo: {
        api: { id: apiId }
      },
      updateApi
    } = this.props;
    updateApi({
      accessToken,
      apiId,
      notes
    });
  };

  closeNotesEditor = notes => {
    this.setState({
      notesEditorOpen: false,
      notes: notes
    });
  };

  //// Response dialog (just in case) ////

  showToastNotif = (message, variant) => {
    this.setState({
      showToastNotif: true,
      toastMessage: message,
      toastVariant: variant
    });
  };

  closeToastNotif = () => {
    this.setState({ showToastNotif: false });
  };

  downloadSpecFile(specFile, specName) {
    var blob = new Blob([specFile], { type: "application/json;charset=utf-8" });
    const blobName = `${specName}.json`;
    saveAs(blob, blobName);
  }

  //// Render ////

  render() {
    const {
      apiInfo: { fetchingApi, api, awaitingResponse, apiEditError }
    } = this.props;

    const {
      showToastNotif,
      toastMessage,
      toastVariant,

      zSpec,
      zTitle,
      zContact,
      zSpecifics,

      envsAvailable,
      envSelected,
      envSelectedValue,
      instancesAvailable,
      instanceSelected,
      instanceSelectedValue
    } = this.state;

    const style = { textAlign: "center" };
    let rateLimitValue = "";
    let rateLimitType = "";
    let rateLimitPerAppValue = "";
    let rateLimitPerAppType = "";

    if (
      instanceSelected &&
      instanceSelected.config &&
      instanceSelected.config.advancedProxySettings &&
      instanceSelected.config.advancedProxySettings.rateLimit
    ) {
      const rateLimit = instanceSelected.config.advancedProxySettings.rateLimit;
      rateLimitValue = rateLimit.slice(0, -2);
      rateLimitType = rateLimit.slice(-2);
      if (rateLimitType === "pm") {
        rateLimitValue += " Calls/Minute";
      } else {
        rateLimitValue += " Calls/Second";
      }
    }

    if (
      instanceSelected &&
      instanceSelected.config &&
      instanceSelected.config.advancedProxySettings &&
      instanceSelected.config.advancedProxySettings.rateLimitPerApp
    ) {
      const rateLimitPerApp =
        instanceSelected.config.advancedProxySettings.rateLimitPerApp;
      rateLimitPerAppValue = rateLimitPerApp.slice(0, -2);
      rateLimitPerAppType = rateLimitPerApp.slice(-2);
      if (rateLimitPerAppType === "pm") {
        rateLimitPerAppValue += " Calls/Minute";
      } else {
        rateLimitPerAppValue += " Calls/Second";
      }
    }

    return (
      <>
        {fetchingApi ? (
          <div className="loading-container">
            <h3>Loading API Information...</h3>
            <CircularProgress />
          </div>
        ) : api && !api.message ? (
          <>
            <LayoutGrid>
              <LayoutGridCell span={12} spanTablet={12} spanPhone={12}>
                <Elevation
                  z={zSpec}
                  className={"c-dash-card__wrapper"}
                  transition
                  onMouseEnter={() => this.setState({ zSpec: 24 })}
                  onMouseLeave={() => this.setState({ zSpec: 10 })}
                >
                  <Card
                    className={
                      "c-dash-card c-form-subscriptions c-form-subscriptions--step1"
                    }
                  >
                    <CardHeader className="spec-info-spec-header">
                      <div className="spec-info-fragment spec-info-title-fragment">
                        <CardTitle large>{api.name}</CardTitle>
                        <div className="spec-info-status-indicator">
                          <StatusIndicatorInline
                            type={typeForStatus(api.status)}
                          >
                            {formatStatus(api.status)}
                          </StatusIndicatorInline>
                        </div>
                      </div>
                    </CardHeader>
                    <CardText className="api-info-spec-body">
                      <SpecFileView lines={this.state.specFileLines} />
                    </CardText>
                    <CardActions className="api-info-spec-actions">
                      <div className="api-info-env-version-select">
                        <Select
                          label="Environment"
                          value={envSelectedValue}
                          disabled={!envsAvailable.length}
                          onChange={this.setSelectedEnv}
                        >
                          {envsAvailable.map(env => (
                            <option key={env.name} value={env.name}>
                              {env.name}
                            </option>
                          ))}
                        </Select>
                      </div>
                      <div className="api-info-env-version-select">
                        <Select
                          label="Version"
                          value={instanceSelectedValue}
                          disabled={!instancesAvailable.length}
                          onChange={this.setSelectedInstance}
                        >
                          {instancesAvailable.map(inst => (
                            <option key={inst.id} value={inst.id}>
                              {inst.version}
                            </option>
                          ))}
                        </Select>
                      </div>
                      <Button
                        text="Download Spec"
                        variant={BUTTON_VARIANTS.SECONDARY}
                        icon={<IconFileDownload />}
                        className="margin-sides-5"
                        onClick={() =>
                          this.downloadSpecFile(this.state.specFile, api.name)
                        }
                        isDisabled={!this.state.specFile}
                      />
                      <Button
                        text="Edit Admin Settings"
                        variant="secondary"
                        icon={<IconEdit />}
                        className="margin-sides-5"
                        onClick={this.openEditDialog}
                      />
                      {
                        api.status === 'in_review' &&
                        <Button
                          text="Approve"
                          variant="secondary"
                          icon={<IconCheck />}
                          className="margin-sides-5"
                          onClick={this.openApproveConfirmation}
                        />
                      }
                    </CardActions>
                  </Card>
                </Elevation>
              </LayoutGridCell>
              <LayoutGridCell span={4} spanTablet={12} spanPhone={12}>
                <Elevation
                  z={zTitle}
                  className={"c-dash-card__wrapper api-info-lower-card"}
                  transition
                  onMouseEnter={() => this.setState({ zTitle: 24 })}
                  onMouseLeave={() => this.setState({ zTitle: 10 })}
                >
                  <Card
                    className={
                      "c-dash-card c-form-subscriptions c-form-subscriptions--step1 spec-info-meta-body api-info-lower-card"
                    }
                  >
                    <CardHeader>
                      <CardTitle large>
                        <b>API:</b> {api?.name}
                      </CardTitle>
                      <br />
                      <CardSubtitle>
                        <b>ID: </b> {api?.id}
                      </CardSubtitle>
                      <br />
                      <CardSubtitle>
                        <b>Owning Org: </b> {api?.orgId}
                      </CardSubtitle>
                      <br />
                      <CardSubtitle>
                        <b>Summary: </b> {api?.summary}
                      </CardSubtitle>
                      <br />
                      <CardSubtitle>
                        <b>Description: </b> {api?.description}
                      </CardSubtitle>
                      <br />
                      <CardSubtitle>
                        <b>Tags: </b>{" "}
                        {api?.tags?.length ? api.tags.join(", ") : "None"}
                      </CardSubtitle>
                      <br />
                      <CardSubtitle>
                        <b>Visibility: </b> {api?.visibility}
                      </CardSubtitle>
                      <br />
                      <CardSubtitle>
                        <b>Privacy Protection: </b>{" "}
                        {api?.privacyProtection ? "Yes" : "No"}
                      </CardSubtitle>
                      <br />
                      <CardSubtitle>
                        <b>API Field Management: </b>{" "}
                        {api?.verticalScoping ? "Yes" : "No"}
                      </CardSubtitle>
                      <br />
                      <CardSubtitle>
                        <b>Created: </b>
                        {api?.created
                          ? new Date(api.updated).toLocaleString()
                          : "N/A"}
                      </CardSubtitle>
                      <br />
                      <CardSubtitle>
                        <b>Last Updated: </b>
                        {api?.updated
                          ? new Date(api.updated).toLocaleString()
                          : "N/A"}
                      </CardSubtitle>
                    </CardHeader>
                  </Card>
                </Elevation>
              </LayoutGridCell>
              <LayoutGridCell span={4} spanTablet={12} spanPhone={12}>
                <Elevation
                  z={zContact}
                  className={"c-dash-card__wrapper api-info-lower-card"}
                  transition
                  onMouseEnter={() => this.setState({ zContact: 24 })}
                  onMouseLeave={() => this.setState({ zContact: 10 })}
                >
                  <Card
                    className={
                      "c-dash-card c-form-subscriptions c-form-subscriptions--step1 api-info-lower-card"
                    }
                  >
                    <CardHeader>
                      <CardTitle large>
                        <b>API Instance: </b>
                        {instanceSelected?.name}
                      </CardTitle>
                      <br />
                      <CardSubtitle>
                        <b>ID: </b> {instanceSelected?.id}
                      </CardSubtitle>
                      <br />
                      <CardSubtitle>
                        <b>Version: </b>
                        {instanceSelected?.version}
                      </CardSubtitle>
                      <br />
                      <CardSubtitle>
                        <b>Environment: </b>{" "}
                        {instanceSelected?.environment}
                      </CardSubtitle>
                      <br />
                      <CardSubtitle>
                        <b>Proxy URL: </b>
                        {instanceSelected ? (
                          <a
                            target="_blank"
                            href={instanceSelected.config.proxyUrl}
                          >
                            {instanceSelected.config.proxyUrl}
                          </a>
                        ) : null}
                      </CardSubtitle>
                      <br />
                      <CardSubtitle>
                        <b>Target URL: </b>
                        {instanceSelected ? (
                          <a
                            target="_blank"
                            href={instanceSelected.config.targetUrl}
                          >
                            {instanceSelected.config.targetUrl}
                          </a>
                        ) : null}
                      </CardSubtitle>
                      <br />
                      <CardSubtitle>
                        <b>Activation Model: </b>{" "}
                        {envSelected &&
                          ACTIVATION_TEXT[envSelected.activation.model]}
                      </CardSubtitle>
                      <br />
                      <CardSubtitle>
                        <b>Rate Limit (overall): </b>{" "}
                        {rateLimitValue ? rateLimitValue : "N/A"}
                      </CardSubtitle>
                      <br />
                      <CardSubtitle>
                        <b>Rate Limit (per App): </b>{" "}
                        {rateLimitPerAppValue ? rateLimitPerAppValue : "N/A"}
                      </CardSubtitle>
                      <br />
                      <CardSubtitle>
                        <b>Is LongRunning: </b>{" "}
                        {api.isLongRunningAPI ? "true" : "false"}
                      </CardSubtitle>
                      {envSelected &&
                      envSelected.activation.model ===
                        ACTIVATION_MODELS.ADMIN_API ? (
                        <CardSubtitle>
                          <b>Admin API URL: </b>
                          {envSelected ? (
                            <a
                              target="_blank"
                              href={envSelected.activation.adminApiUrl}
                            >
                              {envSelected.activation.adminApiUrl}
                            </a>
                          ) : null}
                        </CardSubtitle>
                      ) : null}
                    </CardHeader>
                  </Card>
                </Elevation>
              </LayoutGridCell>
              <LayoutGridCell span={4} spanTablet={12} spanPhone={12}>
                <Elevation
                  z={zSpecifics}
                  className={"c-dash-card__wrapper api-info-lower-card"}
                  transition
                  onMouseEnter={() => this.setState({ zSpecifics: 24 })}
                  onMouseLeave={() => this.setState({ zSpecifics: 10 })}
                >
                  <Card
                    className={
                      "c-dash-card c-form-subscriptions c-form-subscriptions--step1 api-info-lower-card"
                    }
                  >
                    <CardHeader className="spec-info-notes-header">
                      <CardTitle large>Notes</CardTitle>
                      <Button
                        text="Edit"
                        variant={BUTTON_VARIANTS.SECONDARY}
                        icon={<IconEdit />}
                        className="margin-sides-5"
                        onClick={() => this.openNotesEditor()}
                        isDisabled={this.state.notesEditorOpen}
                      />
                    </CardHeader>
                    <CardText className="spec-info-notes-body">
                      {this.state.notesEditorOpen ? (
                        <NotesEditor
                          initialNotes={this.state.notes}
                          notesSaving={awaitingResponse}
                          notesError={apiEditError}
                          saveAndClose={this.saveAndCloseNotesEditor}
                          close={this.closeNotesEditor}
                        />
                      ) : (
                        this.state.notes &&
                        this.state.notes.split("\n").map(line => (
                          <>
                            {line}
                            <br />
                          </>
                        ))
                      )}
                    </CardText>
                  </Card>
                </Elevation>
              </LayoutGridCell>
            </LayoutGrid>
          </>
        ) : (
          <>
            <h1 style={style}>API Not Found</h1>
          </>
        )}
        {this.state.apiEditDialogOpen ? (
          <ApiEditDialog
            open={this.state.apiEditDialogOpen}
            close={this.closeEditDialog}
            closeAfterSave={this.closeEditDialogFromSave}
            editedApi={api}
            awaitingResponse={this.props.apiInfo.awaitingResponse}
            errorMessage={this.props.apiInfo.errorMessage}
            apiEditResponse={this.props.apiInfo.apiEditResponse}
            apiEditError={this.props.apiInfo.apiEditError}
            updateApiMetadata={this.updateApiMetadata}
            showToastNotif={this.showToastNotif}
            clearApiUpdateData={this.props.clearAfterApiUpdate}
          />
        ) : null}
        <Confirm
          open={this.state.approveConfirmationOpen}
          onCancel={() => this.setState({ approveConfirmationOpen: false })}
          onAccept={this.approveApi}
          message={
            "Are you sure you want to approve this API?"
          }
        />
        {showToastNotif ? (
          <ToastNotification
            open={showToastNotif}
            onCancel={this.closeToastNotif}
            message={toastMessage}
            variant={toastVariant}
          />
        ) : null}
      </>
    );
  }
}

ApiInfo = withNavigationToApiAdmin(ApiInfo);
class ApiInfoContainer extends Component {
  render() {
    return this.props.auth.isAuthenticated ? (
      <ApiInfo {...this.props} />
    ) : (
      <div></div>
    );
  }
}
export default withAuth(ApiInfoContainer);

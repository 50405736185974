import React, { Component, useEffect, useState } from "react";
import {
  Tabs,
  TabPanel,
  Tab,
  Input,
  INPUT_ICON_POSITIONS,
  INPUT_SIZES,
  SearchInput
} from "cdk-radial";
import axios from "axios";
import config from "../config/app.conf.json";
import "./styles.css";
import ContractedApisTab from "./ContractedApisTab";
import ContractedBundlesTab from "./ContractedbundlesTab";

export default function ContractedApisReview({ auth, contractedApis, contractedBundles, setSelectedBundles, selectedBundles, handleApiSelection, setSelectedApis, selectedApis, selectedApisFromBundles,apisFromContractedBundles}) {
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [apiList,setApiList]=useState([]);
  const [bundleList,setBundleList]=useState([]);
  const { accessToken } = auth;
  const fetchApis = () => {
    axios
      .get(`${config.api.api_gateway_url}/v1/admin/bundle-apis`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        setApiList(response?.data);
      })
      .catch((e) => {
      console.log("error",e);
      });
  };
  const fetchBundles = () => {
    axios
      .get(`${config.api.api_gateway_url}/v2/admin/bundles`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        setBundleList(response?.data);
      })
      .catch((e) => {
        console.log("error",e);
      });
  };
  useEffect(()=>{
    fetchApis();
    fetchBundles();
  },[])

  return (
    <div>
      <div className="search_and_apis_button_container">
        <b className="contract-review-id-title">
          Select Bundles or APIs to be Contracted{" "}
        </b>

        {activeItemIndex === 1 && (
          <div className="apis-search-box">
            <SearchInput
              type="text"
              value={searchQuery}
              iconPosition={INPUT_ICON_POSITIONS.LEADING}
              isAlwaysExpanded
              placeholder="Search"
              size={INPUT_SIZES.SMALL}
              onChange={setSearchQuery}
            />
          </div>
        )}
      </div>
      <div className="api-contracted-tabs">
        <Tabs
          activeItemIndex={activeItemIndex}
          items={[
            {
              id: "Bundles",
              panelId: "Bundles",
              renderPanel: (tabProps) => (
                <TabPanel {...tabProps}>
                  <ContractedBundlesTab auth={auth}  bundleList={bundleList} contractedBundles={contractedBundles}
                   setSelectedBundles= {setSelectedBundles} selectedBundles={selectedBundles}/>
                </TabPanel>
              ),
              renderTab: (tabProps) => (
                <Tab
                  label={`Bundles (${selectedBundles?.length} selected)`}
                  {...tabProps}
                  hideLabel={false}
                />
              ),
            },
            {
              id: "Apis",
              panelId: "Apis",
              renderPanel: (tabProps) => (
                <TabPanel {...tabProps}>
                 {apiList.length>0 && <ContractedApisTab auth={auth} apiList={apiList} contractedApis={contractedApis}
                 setSelectedApis={setSelectedApis} selectedApis={selectedApis}
                 selectedApisFromBundles={selectedApisFromBundles} apisFromContractedBundles={apisFromContractedBundles}
                 searchQuery={searchQuery}/>}
                </TabPanel>
              ),
              renderTab: (tabProps) => (
                <Tab
                  label={`APIs (${selectedApis?.length} selected)`} 
                  hideLabel={false}
                  {...tabProps}
                />
              ),
            },
          ]}
          onActiveItemChange={setActiveItemIndex}
          tabsLabel="Contrated APIs and Bundles Tabs"
        />
      </div>
    </div>
  );
}
